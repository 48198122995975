<template>
  <div class="app-content app-container">
    <!-- 搜索 -->
    <div style="margin-bottom: 20px">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
        @submit.native.prevent
      >
        <el-form-item label="名称" prop="wjdoTitle">
          <el-input
            style="width: 300px"
            v-model="formInline.wjdoTitle"
            placeholder="名称"
            clearable
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" plain @click="addReport">新增</el-button>
        <!-- <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                > -->
      </div>
    </div>
    <!-- 表格 -->
    <!-- {{templateFun(9)}} -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="wjdoId" label="id" width="55"></el-table-column>
        <el-table-column prop="time" label="时间">
          <template slot-scope="scope">{{ scope.row.time | dayjs }}</template>
        </el-table-column>
        <el-table-column prop="wjdoTitle" label="名称"> </el-table-column>
        <el-table-column label="模板">
          <template slot-scope="scope">
            <span>{{ templateFun(scope.row.wjtId) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="wjdoCanEdit" label="是否启用">
          <template slot-scope="scope">
            <span>{{ scope.row.status | isYes }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="wjdoDateBegin" label="投放开始">
        </el-table-column>
        <el-table-column prop="wjdoDateEnd" label="投放结束"> </el-table-column>
        <el-table-column prop="numberPeople" label="参与数"> </el-table-column>
        <el-table-column label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button type="text" @click="editClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="previewClick(scope.row)"
              >预览</el-button
            >
            <el-button type="text" @click="lockClick(scope.row)"
              >锁定</el-button
            >
            <el-button type="text" @click="pushClick(scope.row)"
              >推送</el-button
            >
            <el-button type="text" @click="analysisResultClick(scope.row)"
              >分析结果</el-button
            >
            <!-- @click="analysisResultDownload(scope.row)" -->
            <el-button type="text" @click="analysisResultDownload(scope.row)">
              下载分析结果
              <!-- <a :href="`https://v2.wenjuan.ak.sdcom.gov.cn/doftec/admin-survey/download?id=${scope.row.wjdoId}`" :download="`${scope.row.wjdoTitle}.xls`"> 下载分析结果</a> -->
            </el-button>
            <el-button type="text" @click="reportDownload(scope.row)">
              下载问卷报告
              <!-- <a :href="`https://v2.wenjuan.ak.sdcom.gov.cn/doftec/admin-survey/download?id=${scope.row.wjdoId}`" :download="`${scope.row.wjdoTitle}.xls`"> 下载分析结果</a> -->
            </el-button>
            <el-button type="text" @click="professionDownload(scope.row)">
              下载行业报告
              <!-- <a :href="`https://v2.wenjuan.ak.sdcom.gov.cn/doftec/admin-survey/download?id=${scope.row.wjdoId}`" :download="`${scope.row.wjdoTitle}.xls`"> 下载分析结果</a> -->
            </el-button>

            <!-- <el-button type="text" @click="surveyDiffClick(scope.row)">比对</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div>
        <Pagination
          v-bind:child-msg="pageparm"
          @callFather="callFather"
        ></Pagination>
      </div>
    </div>
    <!-- 下载报告弹窗 -->
    <el-dialog
      title="下载报告进度"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div>
        <!-- 进度条 -->
        <div style="margin-bottom: 10px">
          <el-progress
            :percentage="progressValue"
            :text-inside="true"
            text-color="#fff"
            :stroke-width="22"
            :format="
              () => (progressValue == 100 ? '下载完成' : '下载中，请稍后...')
            "
          ></el-progress>
          <el-button type="text" :loading="progressValue == 100 ? false : true"
            >{{ progressValue }}%
          </el-button>
        </div>

        <el-alert
          title="报告下载过程中请勿关闭弹窗"
          type="warning"
          :closable="false"
        >
        </el-alert>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { surveyListApi, surveyActionApi } from "@/api/survey.js";
import { templateListApi } from "@/api/templateApi.js";
// import request from '@/utils/request.js'
export default {
  // 注册组件
  components: {
    Pagination,
  },
  data() {
    return {
      formInline: {
        wjdoTitle: "",
      },
      tableData: [],
      multipleSelection: [],
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 20,
      },
      templateList: [],
      dialogVisible: false,
      progressValue: 0,
    };
  },
  created() {
    if (sessionStorage.getItem("formInline")) {
      this.formInline = JSON.parse(sessionStorage.getItem("formInline"));
    }
    this.getTemplateFun();
    this.surveyListApi();
  },
  methods: {
    templateFun(id) {
      var list = this.templateList.filter((item) => item.wjtId === id);
      var name = list.length > 0 ? list[0].wjtName : "";
      return name;
    },
    // 获取所有的模板
    async getTemplateFun() {
      var params = {
        start: 0,
        length: 9999,
        draw: 1,
        orderStr: "time desc",
      };
      var res = await templateListApi(params);
      this.templateList = res.data;
    },
    // 获取问卷列表
    async surveyListApi(currentPage, pageSize) {
      var start = currentPage ? currentPage * pageSize - pageSize : 0;
      var params = {
        start: start,
        length: pageSize ? pageSize : 10,
        draw: 1,
        orderStr: "time desc",
      };
      var p = Object.assign(params, this.formInline);
      var res = await surveyListApi(p);
      this.tableData = res.data;
      this.pageparm.total = res.recordsTotal;
    },
    onSubmit() {
      sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      this.surveyListApi();
    },
    resetForm(formInline) {
      sessionStorage.removeItem("formInline");
      this.formInline = {
        name: "",
      };
      this.$refs[formInline].resetFields();
      this.surveyListApi();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页插件事件
    callFather(parm) {
      // this.formInline.page = parm.currentPage
      // this.formInline.limit = parm.pageSize
      this.surveyListApi(parm.currentPage, parm.pageSize);
    },
    // 新增模板
    addReport() {
      this.$router.push({
        path: "/list/add",
      });
    },
    // 删除
    async allDeleteClick() {
      var ids = this.multipleSelection.map((item) => item.wjdoId);
      this.$alert(
        "这确定要删除共" +
          this.multipleSelection.length +
          "条数据吗？删除后将无法恢复",
        "删除提示",
        {
          confirmButtonText: "确定",
          callback: async (action) => {
            if (action === "confirm") {
              var params = {
                ids: ids,
                menuType: "delete",
              };
              var res = await surveyActionApi(params);
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.surveyListApi();
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败：" + res.message,
                });
              }
            }
          },
        }
      );
    },
    // 锁定
    async lockClick(row) {
      console.log("row", row);
      if (row.status === 1) {
        var params = {
          ids: row.wjdoId,
          menuType: "status",
          menuValue: row.status === 1 ? 0 : 1, //0 否 ，1 是
        };
        var res = await surveyActionApi(params);
        if (res.code === 200) {
          this.$message({
            message: "锁定成功",
            type: "success",
          });
          this.surveyListApi();
        }
      } else {
        this.$message({
          message: "当前应用为禁用中，无法锁定",
          type: "error",
        });
      }
    },
    // 推送
    async pushClick(row) {
      // var ids = this.multipleSelection.map((item) => item.wjdoId)
      // if(row.status === 0){
      var params = {
        ids: row.wjdoId,
        menuType: "push",
      };
      var res = await surveyActionApi(params);
      if (res.code === 200) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.surveyListApi();
      }
      // }else{
      //     this.$message({
      //         message: '请先锁定问卷再推送',
      //         type: 'error',
      //     })
      // }
    },
    // 编辑模板
    editClick(row) {
      console.log("row", row);
      this.$router.push({
        path: "/list/edit",
        query: {
          id: row.wjdoId,
        },
      });
    },
    // 预览模板
    previewClick(row) {
      this.$router.push({
        path: "/list/questionnaire/formboard/preview",
        query: {
          id: row.wjtId,
        },
      });
    },
    // 分析结果
    analysisResultClick(row) {
      console.log("分析结果", row);
      // 跳转页面
      this.$router.push({
        path: "/list/analysisResult",
        query: {
          id: row.wjdoId,
        },
      });
    },
    // 下载分析结果
    async analysisResultDownload(row) {
      // var params = {
      //     id: row.wjdoId,
      // }
      this.download(
        "/doftec/admin-survey/download?id=" + row.wjdoId,
        // { params: params },
        `${row.wjdoTitle}.xlsx`
      );
    },
    // 下载行业报告
    professionDownload(row){
      this.$store.commit("SET_isExport", true);
      this.progressValue = 0;
      this.dialogVisible = true;
      setTimeout(() => {
        if (this.dialogVisible) {
          this.download(
            "/doftec/admin-survey/report/situation/industry?id=" + row.wjdoId,
            // { params: params },
            `下载${row.wjdoTitle}行业报告.doc`
          );

          const interval = window.setInterval(() => {
            var isExport = this.$store.state.isExport;
            var code = this.$store.state.code;
            if (code != 200) {
              window.clearInterval(interval);
              setTimeout(() => {
                this.dialogVisible = false;
              }, 2000);
              return false;
            }
            if (isExport) {
              // 根据时间每秒+1

              var random1 = Math.random() * (1 - 3) + 3;
              this.progressValue += Math.trunc(random1);
              if (this.progressValue === 100) {
                window.clearInterval(interval);
              }
            } else {
              // 返回false 数据变更为100
              this.progressValue = 100;
              window.clearInterval(interval);
              setTimeout(() => {
                this.dialogVisible = false;
              }, 2000);
            }
          }, 1000);
        }
      }, 1000);
    },
    // 下载报告
    reportDownload(row) {
      this.$store.commit("SET_isExport", true);
      this.progressValue = 0;
      this.dialogVisible = true;
      setTimeout(() => {
        if (this.dialogVisible) {
          this.download(
            "/doftec/admin-survey/report/generate/word/new?id=" + row.wjdoId+'&online=true',
            // { params: params },
            `下载${row.wjdoTitle}报告.doc`
          );

          const interval = window.setInterval(() => {
            var isExport = this.$store.state.isExport;
            var code = this.$store.state.code;
            if (code != 200) {
              window.clearInterval(interval);
              setTimeout(() => {
                this.dialogVisible = false;
              }, 2000);
              return false;
            }
            if (isExport) {
              // 根据时间每秒+1

              var random1 = Math.random() * (1 - 3) + 3;
              this.progressValue += Math.trunc(random1);
              if (this.progressValue === 100) {
                window.clearInterval(interval);
              }
            } else {
              // 返回false 数据变更为100
              this.progressValue = 100;
              window.clearInterval(interval);
              setTimeout(() => {
                this.dialogVisible = false;
              }, 2000);
            }
          }, 1000);
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.app-content {
  background-color: #fff;
  padding: 20px;
}
</style>
